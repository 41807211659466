.text-headline-indent p {
    margin-left: config('margin.3');
    margin-bottom: config('margin.5');
}

.text-headline-indent strong {
    display: block;
    margin-left: calc(config('negativeMargin.3') * -1);
}

@media (min-width: 768px) {
    .text-indent p{
        text-indent: 50px;
    }

    .text-headline-indent p {
        margin-left: config('margin.6');
    }

    .text-headline-indent strong {
        display: block;
        margin-left: calc(config('negativeMargin.6') * -1);
    }
}
