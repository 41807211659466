@media (max-width: 991px) {
    .navigation__wrapper {
        transition: color 150ms ease-in-out;
        height: 75px;
    }

    .navigation__wrapper:before {
        content: "";
        height: 75px;
        width: 100%;
        position: absolute;
        top:0;
        left: 0;
        background: config('colors.white');
        transform: translateX(100%);
        transition: transform 150ms ease-in-out;
    }

    .navigation__wrapper--open.navigation__wrapper--open {
        color: config('colors.black') !important;
    }

    .navigation__wrapper--open:before{
        transform: translateX(0);
    }

    .navigation {
        margin-top: 55px;
        height: calc(100% - 55px);
        transform: translateX(100%);
        transition: transform 150ms ease-in-out;
        color: config('colors.black') !important;
    }

    .navigation.active {
        transform: translateX(0);
    }
}

