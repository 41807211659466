@font-face {
    font-family: 'Rotis';
    font-weight: 30;
    src: url('../fonts/39B65B_3_0.eot');
    src: url('../fonts/39B65B_3_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39B65B_3_0.woff2') format('woff2'),url('../fonts/39B65B_3_0.woff') format('woff'),url('../fonts/39B65B_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Rotis';
    font-weight: 400;
    src: url('../fonts/39B65B_1_0.eot');
    src: url('../fonts/39B65B_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39B65B_1_0.woff2') format('woff2'),url('../fonts/39B65B_1_0.woff') format('woff'),url('../fonts/39B65B_1_0.ttf') format('truetype');
}


@font-face {
    font-family: 'Rotis';
    font-weight: 700;
    src: url('../fonts/39B65B_2_0.eot');
    src: url('../fonts/39B65B_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39B65B_2_0.woff2') format('woff2'),url('../fonts/39B65B_2_0.woff') format('woff'),url('../fonts/39B65B_2_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Rotis';
    font-weight: 900;
    src: url('../fonts/39B65B_0_0.eot');
    src: url('../fonts/39B65B_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39B65B_0_0.woff2') format('woff2'),url('../fonts/39B65B_0_0.woff') format('woff'),url('../fonts/39B65B_0_0.ttf') format('truetype');
}