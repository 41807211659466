.cookie-banner{
    z-index: 100;
}

.cookie-banner .backdrop {
    will-change: opacity, visibility;
    opacity: 1;
    transition: 200ms opacity ease-in-out, 0ms visibility;
}

.cookie-banner .cookie-banner__content {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.6);
    will-change: transform;
    transition: 200ms transform ease-in-out;
}

.cookie-banner.cookie-banner__hidden .backdrop {
    opacity: 0;
    visibility: hidden;
}

.cookie-banner.cookie-banner__hidden .cookie-banner__content {
    transform: translateY(100%);
}

