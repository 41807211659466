html{
    font-size: 16px;
}

body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Rotis, arial, sans-serif;
    overflow-x: hidden;
} 

@media (min-width: 992px) {
    body{
        padding-top: 60px;
    }
}

svg {
    fill: currentColor;
}

a{
    @apply .no-underline .text-black;
}
 
a:hover,
a:focus{
    @apply .underline .text-black;
}

strong{
    font-weight: 900;
}

accordion{
    display: none;
}