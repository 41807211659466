.lazyload-image {
    background-color: #dddddd;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transition: opacity 150ms;
}

.lazyloaded {
    opacity: 1;
}