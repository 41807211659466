.dropdown ul {
    display: none;
}

.dropdown--level-1 {
    transform: translateY(100%);
    z-index: -1;
}

.dropdown .active + ul {
    display: block;
}

@media (max-width: 991px) {
    .dropdown .dropdown-open + ul {
        display: block;
    }

    .dropdown li{
        max-width: 100%;
    }

    .dropdown--level-1 {
        transform: none;
        z-index: 0;
    }
}