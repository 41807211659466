.image-pin{
    @apply .overflow-hidden .relative;
}

.image-pin__spacer{
    @apply .float-left .w-full .h-screenHalf .relative .block;
}

.image-pin__image{
    @apply .float-left .w-full .h-screenHalf .bg-cover;
    background-position: center top;
    z-index: 1;
}

.image-pin__image--start{
    @apply .absolute .pin-t;
}

.image-pin__image--fixed{
    @apply .fixed .pin-t;
}

.image-pin__image--end{
    @apply .absolute .pin-b;
}

.image-pin__content{
    @apply .float-left .w-full .min-h-imagePinMobile .flex .flex-col .px-5 .py-6 .font-bold;

    padding-bottom: calc(config('padding.6') + 20vh);
}

@media (min-width: 992px){
    .image-pin__spacer{
        @apply .w-1/2 .h-screen;
    }

    .image-pin__image{
        @apply .w-1/2 .h-screen;
    }

    .image-pin__content{
        @apply .w-1/2 .min-h-imagePin .pt-8 .px-6;
        padding-bottom: calc(config('padding.8') + 10vh);
    }
}

@media (min-width: 1200px){
    .image-pin__content{
        @apply .px-7;
    }
}