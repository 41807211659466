.hamburger{
    transition: transform 400ms;
}

.hamburger .line {
    fill: none;
    transition: stroke 400ms, stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke-width:5.5; 
    stroke: config('colors.black');
    stroke-linecap:round;
}

.index-nav:not(.navigation__wrapper--open) .hamburger .line {
    stroke: config('colors.white');
}

.hamburger .top {
    stroke-dasharray: 40 121;
}
.hamburger .bottom {
    stroke-dasharray: 40 121;
}

.hamburger.active{
    transform: rotate(45deg);
}

.hamburger.active .top {
    stroke-dashoffset: -68px;
}
.hamburger.active .bottom {
    stroke-dashoffset: -68px;
}